import React from 'react';
import { navigate } from 'gatsby';
import ZipcodePage from 'src/px/ZipcodePage';
import { Page } from '../../components';

export const SlasherZipcodeView = ({}) => {
  return (
    <Page
      context="Slasher"
      progress={0.25}
      metadata={{
        title: 'Slash your premiums',
        description: 'See how much you could save on your current coverage',
        image: '/img/og/slasher.png',
      }}
    >
      <ZipcodePage flow="Slasher" onNext={() => navigate('/slasher/savings')} />
    </Page>
  );
};

export default SlasherZipcodeView;
